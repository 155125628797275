import Testifier1 from "../../assets/Testifiers/Testifier1.png";
import Testifier2 from "../../assets/Testifiers/Testifier2.png";
import Testifier3 from "../../assets/Testifiers/Testifier3.png";
import Testifier4 from "../../assets/Testifiers/Testifier4.png";

export const TestimonialList = [
  {
    avatar: Testifier1,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier2,
    testimony:
      "Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design, outsour..",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier3,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier4,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier1,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier2,
    testimony:
      "Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design, outsour..",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier3,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
  {
    avatar: Testifier4,
    testimony:
      "I owe these guys my life. Already used their service agency for my latest two projects.",
    handle: "- @thepatwalls",
  },
];
